import React from "react";
import data from "../data/content";

const Services = () => {
  const { title, items, image } = data.services;
  return (
    <section
      className="flex justify-center bg-gradient-akigreen2 !py-0"
      id="services"
      style={{scrollMarginTop: 70}}
    >
      <div className="flex flex-col mx-auto lg:max-w-[80%] pb-8">
        <div className="flex flex-col-reverse md:flex-row items-center px-16 py-16">
          <div className="md:w-1/2 pt-16 md:pt-0 pr-16">
            <span className="text-darkGreen-100 text-lg font-extrabold">
              ¿Qué servicios brindamos?
            </span>
            <br />
            <hr className="w-32 bg-darkGreen-100 h-1 my-4" />
            <p className="text-black text-xl md:text-2xl font-bold mb-8 text-justify mt-4">
              {title}
            </p>
            <br />
          </div>
          <div className="md:w-1/2 md:pt-8">
            <img
              src={image}
              alt=""
              className="flex h-auto w-full"
            />
          </div>
        </div>

        <div className="flex flex-col flex-wrap justify-center md:flex-row mx-auto gap-9 px-16 md:py-10">
          {items.map(i => { return(
            <div 
              key={i.id}
              className="flex flex-col md:w-1/3 lg:w-1/4 shadow-lg max-w-[390px] min-w-[220px] border-darkGreen-100 rounded-[25px] bg-white p-8">
              <img
                className="w-16 h-auto mx-auto md:mx-0"
                src={i.iconUrl}
                alt=""
              />
              <span className="text-xl font-extrabold text-darkGreen-100 mt-3 text-center md:text-left">
                {i.title}
              </span>
              <br />
              <p className="text-lg text-black text-justify">
                {i.description}
              </p>
              <br />
              {/* <img className="w-12 h-auto" src="img/flecha-derecha.png" alt="" /> */}
            </div>
          )})}
        </div>
      </div>
    </section>
  );
};

export default Services;
