import React from "react";
import data from "../data/content";

const Hero = () => {
  const { title, phrase, phrase2, ctaUrl, ctaText, imageUrl } = data.hero;
  return (
    <section className="flex justify-center bg-gradient-akigreen !p-0" id="top">
      <div className="flex flex-col-reverse justify-center items-center md:flex-row lg:max-w-[80%]">
        <div className="flex flex-col space-y-4 md:space-y-6 text-left p-10 md:pr-8 md:w-1/2 lg:w-2/5">
          <span className="text-2xl text-center md:text-left font-extrabold leading-[36px] text-darkGreen-100">
            {title}
          </span>
          <div className="text-xl text-center md:text-left text-black">
            <span>{phrase}</span><br/>
            <span>{phrase2}</span>
          </div>
          <button className="w-auto text-white cursor-pointer font-semibold text-md py-3 px-5 rounded-md mx-auto md:ml-0 shadow-xl hover:shadow-sm hover:translate-y-[1px] hover:bg-darkGreen-100 bg-darkGreen-80 border-none hover:text-white hover:scale-105">
            <a
              href={ctaUrl}
              rel="noreferrer"
              target="_blank"
              className="appearance-none text-white hover:text-white visited:text-white"
            >
              {ctaText}
            </a>
          </button>
        </div>
        <div className="md:w-1/2 lg:w-3/5">
          <img
            src={imageUrl}
            alt="recojo residuos medicos"
            className="flex w-full h-auto md:rounded-[15px] md:p-10 md:pl-0"
          />
        </div>
      </div>
    </section>
  );
};

export default Hero;
