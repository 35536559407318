import React from "react";
import data from "../data/content";

const Contact = () => {
  const { address, ctaText, ctaUrl, phone1, phone2, social, emails } =
    data.contact;
  return (
    <section
      className="bg-lemonGreen-100 !py-0"
      id="contact"
      style={{ scrollMarginTop: 70 }}
    >
      {/* container flex-1 - 2*/}
      <div className="flex flex-col-reverse md:flex-row lg:max-w-[80%] mx-auto justify-center">
        {/*  f1 - 1/2 */}
        <div className="flex justify-center p-10 lg:w-full">
          <div className="flex flex-col items-center lg-flex-row space-y-4">
            <div className="flex flex-col lg:flex-row items-center text-center space-y-5 lg:space-x-8">
              <div>
                <span className="text-darkGreen-100 font-bold text-2xl mb-6">
                  Teléfonos
                </span>
                <p className="text-white font-semibold text-sm cursor-pointer">
                  <a href={`tel:${phone1}`}>{`${phone1}`}</a>
                </p>
                <p className="text-white font-semibold text-sm cursor-pointer">
                  <a href={`tel:${phone1}`}>{`${phone2}`}</a>
                </p>
              </div>
              <div>
                <span className="text-darkGreen-100 font-bold text-2xl mb-4">
                  Email
                </span>
                <p className="text-white font-semibold text-sm cursor-pointer">
                  {emails.map((e) => {
                    return (
                      <>
                        <a href={`mailto:${e.email}`}>{e.email}</a>
                        <br />
                      </>
                    );
                  })}
                </p>
                <br />
              </div>
            </div>
            <div className="text-center">
              <span className="text-darkGreen-100 font-bold text-2xl mb-1">
                Dirección
              </span>
              <p className="text-white font-semibold text-md mt-1">{address}</p>
              <br />
            </div>
            <h2 className="text-darkGreen-100 font-bold text-2xl">
              Contáctanos
            </h2>
            <button className="text-white cursor-pointer font-normal p-3 rounded-md mx-auto md:mx-0 shadow-xl hover:shadow-sm hover:translate-y-[1px] hover:bg-darkGreen-100 bg-darkGreen-80 border-none">
              <a
                href={ctaUrl}
                className="text-md font-semibold text-white hover:text-white"
              >
                {ctaText}
              </a>
            </button>
            <div className="flex flex-row gap-x-12 justify-center pt-4">
              {social.map((s) => {
                return (
                  <a key={s.id} href={s.link} target="_blank" rel="noreferrer">
                    <img
                      src={s.iconUrl}
                      alt=""
                      className="w-12 h-auto hover:bg-lightGreen-100 rounded-full"
                    />
                  </a>
                );
              })}
            </div>
          </div>
        </div>
        {/* flex level 1 */}
        <div className="map-section md:pl-10 p-0 md:p-10 lg:w-full">
          <div className="gmap-frame">
            <iframe
              title="map"
              id="gmap-canvas"
              width="100%"
              height="auto"
              frameBorder="0"
              scrolling="no"
              marginHeight="0"
              marginWidth="0"
              src="https://maps.google.com/maps?width=100%25&amp;height=auto&amp;hl=en&amp;q=Avenida%20Ferrero%201280,%20la%20Molina+(Akigreen)&amp;t=&amp;z=16&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"
            >
              <a href="https://www.gps.ie/">gps devices</a>
            </iframe>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Contact;
